import { createStore, Store } from "vuex";

declare module "@vue/runtime-core" {
	// provide typings for `this.$store`
	interface ComponentCustomProperties {
		$store: Store<State>;
	}
}

type State = {
	openContact: string | null;
};

export default createStore<State>({
	state() {
		return {
			openContact: null,
		};
	},
	mutations: {
		setOpenContact(state, payload: { contactId: string }) {
			state.openContact = payload.contactId;
		},
	},
});
