// Import
import { createApp, defineAsyncComponent } from "vue";
import VueClickAway from "vue3-click-away";
import store from "./store";

// Components
// It appears Swiper has some issues with their exports...
// @ts-ignore
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";

// App main
const main = async () => {
	const app = createApp({
		delimiters: ["${", "}"],
		setup() {
			return {
				modules: [Navigation],
			};
		},
		components: {
			LocationsMap: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "LocationsMap" */ "@/js/components/LocationsMap.vue"
					)
			),
			Sidebar: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Sidebar" */ "@/js/components/Sidebar.vue"
					)
			),
			SiteMenu: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "SiteMenu" */ "@/js/components/SiteMenu.vue"
					)
			),
			BlockVideo: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "BlockVideo" */ "@/js/components/Video.vue"
					)
			),
			Articles: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Articles" */ "@/js/components/Articles.vue"
					)
			),
			Swiper,
			SwiperSlide,
		},
	});

	// SwiperCore.use([Navigation, Pagination, EffectFade]);

	app.use(store);
	app.use(VueClickAway);

	const vm = app.mount("#app");
	return vm;
};

// Execute async function
main().then(() => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
